<template>
<div class="profile-container">
	<div class="header-container">
		<div class="flex-grow-1 ml-10">
			<h5><font-awesome-icon icon="user" class="mr-10"/> Kelola Akun</h5>
		</div>
	</div>
	<hr class="bottom-devider"/>
	<div class="body-container mt-10">
		<CRow>
			<CCol col="8">
				<CCard class="p-0">
					<CCardBody>
						<div class="d-flex avatar rounded-circle p-3">
							<div class="flex-grow-0 mr-20 p-5 content-center">
								<img :src="(currentData.profile_picture)? currentData.profile_picture:avatar" alt="user" class="avatar-img rounded-circle" style="width: 150px; height: 150px;">
							</div>
							<div class="flex-grow-1 p-2">
								<div class="d-flex flex-column">
									<label class="label mb-0 small">Username</label>
									<h5 class="bold">{{currentData.username ? currentData.username : '-'}}</h5>
								</div>
								<div class="d-flex flex-column">
									<label class="label mb-0 small">Nama Lengkap</label>
									<h5 class="bold">{{currentData.fullname ? currentData.fullname : '-'}}</h5>
								</div>
								<div class="d-flex flex-column">
									<label class="label mb-0 small">Email</label>
									<h5 class="bold">{{currentData.email ? currentData.email : '-'}}</h5>
								</div>
								<div class="d-flex flex-column">
									<label class="label mb-0 small">Satuan Kerja</label>
									<h5 class="bold">{{currentData.division ? currentData.division : '-'}}</h5>
								</div>
								<div class="d-flex flex-column">
									<label class="label mb-0 small">Jabatan</label>
									<h5 class="bold">{{currentData.position ? currentData.position : '-'}}</h5>
								</div>
								<div class="d-flex flex-column">
									<label class="label mb-0 small">Tanda Tangan Elektronik</label>
									<h5 class="bold">{{currentData.isBsre ? 'Tersedia':'Belum Didaftarkan'}}</h5>
								</div>
								<div class="d-flex flex-column">
									<label class="label mb-0 small">NIK</label>
									<h5 class="bold">{{currentData.nik ? currentData.nik:'-'}}</h5>
								</div>
							</div>
						</div>
					</CCardBody>
				</CCard>
			</CCol>
			<CCol col="4" class="d-flex">
				<CCard class="flex-grow-1 p-0">
					<CCardBody>
						<div class="d-flex flex-column p-3">
							<div class="flex-grow-0 content-center mt-20 mb-20 p-2">
								<CButton class="btn-md text-white" color="success" @click="showFormModal">
									Perbarui Informasi Akun
								</CButton>
							</div>
							<div class="flex-grow-1 content-center mb-20 p-2">
								<CButton class="btn-md" color="info" @click="showPhotoFormModal">
									Ubah Foto
								</CButton>
								<CButton class="btn-md ml-2" v-if="isHaveProfilePicture" color="danger" @click="deletePhotoItem">
									Hapus Foto
								</CButton>
							</div>
							<div class="flex-grow-0 content-center mb-20 p-2">
								<CButton class="btn-md text-white" color="warning" @click="showPasswordFormModal">
									Perbarui Password
								</CButton>
							</div>
							<div class="flex-grow-0 content-center mb-20 p-2">
								<CButton class="btn-md text-white" color="primary" @click="showSpriModal">
									Daftar Sekretaris Pribadi
								</CButton>
							</div>
							<div class="flex-grow-0 content-center mb-20 p-2">
								<CButton class="btn-md text-white" color="secondary" @click="showEmailModal">
									Pengaturan Auto-Reply Email
								</CButton>
							</div>
						</div>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>

    <CModal :show.sync="modalUser.isShow" 
			:closeOnBackdrop="false">
		<template #header>
			<h5 class="modal-title">{{ modalUser.modalTitle }}</h5>
		</template>
		<div class="p-1">
			<CInput 
				v-model="formUser.input.userId"
				type="hidden"
				:is-valid="formUser.validate.userId"/>
			<CInput
				v-model="formUser.input.username"
				label="Username"
				placeholder="Masukan nama unik"
				:is-valid="formUser.validate.username"
				@change="validateForm('username')"/>
			<CInput
				v-model="formUser.input.fullname"
				label="Nama Lengkap"
				placeholder="Masukan nama lengkap"/>
			<CInput
				v-model="formUser.input.email"
				label="Email"
				type="email"
				placeholder="nama@mail.com"/>
			<CInput
				v-model="formUser.input.nik"
				label="NIK"
				placeholder="Nomor Induk Kependudukan"/>
		</div>
		<template #footer>
			<CButton @click="closeFormModal" class="btn-sm">Batal</CButton>
			<CButton @click="updateItem" color="success" class="btn-sm text-white" :disabled="formUser.submitBtn.disabled">
				<font-awesome-icon v-if="formUser.submitBtn.loader" icon="spinner" spin/> {{ formUser.submitBtn.text }}
			</CButton>
		</template>
	</CModal>
	<CModal :show.sync="modalPassword.isShow" 
			:closeOnBackdrop="false">
		<template #header>
			<h5 class="modal-title">{{ modalPassword.modalTitle }}</h5>
		</template>
		<div class="p-1">
			<CInput 
				v-model="formPassword.input.userId"
				type="hidden"
				:is-valid="formPassword.validate.userId"/>
			<CInput
				v-model="formPassword.input.password"
				label="Password Lama"
				placeholder="Masukan password lama Anda"
				:is-valid="formPassword.validate.password"
				:type="oldPasswordType"
				@change="validatePassword('password')">
				<template #append-content><font-awesome-icon @click="()=> isToggleShowOldPassword = !isToggleShowOldPassword" :icon="oldPasswordIcon"/></template>
			</CInput>
			<CInput
				v-model="formPassword.input.newPassword"
				label="Password Baru"
				placeholder="Masukan password baru Anda"
				:is-valid="formPassword.validate.newPassword"
				:type="newPasswordType"
				@change="validatePassword('newPassword')">
				<template #append-content><font-awesome-icon @click="()=> isToggleShowNewPassword = !isToggleShowNewPassword" :icon="newPasswordIcon"/></template>
			</CInput>
			<CInput
				v-model="formPassword.input.confirmPassword"
				label="Konfirmasi Password Baru"
				placeholder="Konfirmasi password baru Anda"
				:is-valid="formPassword.validate.confirmPassword"
				:type="confirmPasswordType"
				@change="validatePassword('confirmPassword')">
				<template #append-content><font-awesome-icon @click="()=> isToggleShowConfirmPassword = !isToggleShowConfirmPassword" :icon="confirmPasswordIcon"/></template>
			</CInput>
		</div>
		<template #footer>
			<CButton @click="closePasswordFormModal" class="btn-sm">Batal</CButton>
			<CButton @click="updatePassword" color="success" class="btn-sm text-white" :disabled="formPassword.submitBtn.disabled">
				<font-awesome-icon v-if="formPassword.submitBtn.loader" icon="spinner" spin/> {{ formPassword.submitBtn.text }}
			</CButton>
		</template>
	</CModal>
    <CModal :show.sync="modalPhotoUser.isShow" 
			:closeOnBackdrop="false">
		<template #header>
			<h5 class="modal-title">{{ modalPhotoUser.modalTitle }}</h5>
		</template>
        <div class="d-flex flex-column p-1">
            <label class="label small">Pilih Foto/Gambar</label>
            <CInputFile
                label="Pilih Foto/Gambar"
                accept="image/*"
                :custom="true"
                :placeholder="formPhotoUser.placeholderFile"
                @change="validatePhoto"/>
        </div>
		<template #footer>
			<CButton @click="closePhotoFormModal" class="btn-sm">Batal</CButton>
			<CButton @click="updatePhotoItem" color="success" class="btn-sm text-white" :disabled="formPhotoUser.submitBtn.disabled">
				<font-awesome-icon v-if="formPhotoUser.submitBtn.loader" icon="spinner" spin/> {{ formPhotoUser.submitBtn.text }}
			</CButton>
		</template>
	</CModal>


	<!---- Spri Modal------>
	<CModal :show.sync="spriModal.isShow" 
			:closeOnBackdrop="false">
		<template #header>
			<h5 class="modal-title">Daftar Spri Anda</h5>
		</template>
		<div class="d-flex flex-column p-1">
            <v-data-table
                :loading="loading"
                loading-text="Memuat Data..."
                :headers="spriModal.headers"
                :server-items-length="spriModal.dataList.length"
                :multi-sort="false"
                :items="spriModal.dataList"
                class="elevation-1">
                    <template v-slot:item.access="{ item }">
                        <v-icon small class="mr-2 text-info" v-if="item.is_link_user_active" @click="changeAccess(item)">
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon small class="text-danger" v-else @click="changeAccess(item)">
                            mdi-close-circle
                        </v-icon>
                    </template>
                    <template v-slot:no-data>
                        <span>Data Tidak Ditemukan</span>
                    </template>
            </v-data-table>
		</div>

		<template #footer>
			<CButton @click="spriModal.isShow = false" class="btn-sm" color="success">Tutup</CButton>
		</template>
	</CModal>

	<!---- Email Ticket Reply Modal------>
	<CModal :show.sync="emailReplyModal.isShow" 
			:closeOnBackdrop="false">
		<template #header>
			<h5 class="modal-title">Pengaturan Auto Reply Email</h5>
		</template>
		<div class="d-flex flex-column p-1">
			<CRow>
				<CCol>Izinkan Kirim Update Tiket</CCol>
				<CCol>
					<v-icon small class="mr-2 text-info" v-if="this.emailReplyModal.allowedTicket" @click="inputCheckAllowed(false)">
                            mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon small class="text-danger" v-else @click="inputCheckAllowed(true)">
                        mdi-close-circle
                    </v-icon>
				</CCol>
			</CRow>
		</div>

		<template #footer>
			<CButton @click="emailReplyModal.isShow = false" class="btn-sm">Batal</CButton>
			<CButton @click="updateEmail" color="success" class="btn-sm text-white">
				Simpan
			</CButton>
		</template>
	</CModal>
</div>
</template>

<script>
import {
    createNamespacedHelpers,
    mapState,
    mapGetters
} from 'vuex'

const avatar = require('@/assets/img/avatar.svg')
const logo = require('@/assets/img/logo.png')
export default {
    names: 'Profile',
	data() {
        const modalUser = {
			isShow: false,
			modalTitle: 'Perbarui Informasi Akun'
        }
        const formUser = {
			input: {
				userId: null,
				username: null,
				fullname: null,
				email: null,
				nik: null
			},
			defaultValue: {
				userId: 0,
				username: '',
				fullname: '',
				email: '',
				nik: ''
			},
			validate: {
				userId: null,
				username: null
			},
			submitBtn: {
                loader: false,
                text: 'Simpan',
				disabled: true
			}
		}
		const modalPassword = {
			isShow: false,
			modalTitle: 'Perbarui Password'
        }
        const formPassword = {
			input: {
				userId: null,
				password: null,
				newPassword: null,
				confirmPassword: null
			},
			defaultValue: {
				userId: 0,
				password: '',
				newPassword: '',
				confirmPassword: ''
			},
			validate: {
				userId: null,
				password: null,
				newPassword: null,
				confirmPassword: null
			},
			submitBtn: {
                loader: false,
                text: 'Simpan',
				disabled: true
			}
        }
        const modalPhotoUser = {
			isShow: false,
			modalTitle: 'Ubah Foto Profil'
        }
        const formPhotoUser = {
			input: {
				file: null
			},
			defaultValue: {
				file: []
            },
            validate: {
				file: null
            },
            placeholderFile: 'Belum memilih foto/gambar',
			submitBtn: {
                loader: false,
                text: 'Simpan',
				disabled: true
			}
		}
		const division = '-'

		const spriModal = {
			isShow: false,
			headers: [
				{ text: 'Username', value: 'username', align: 'left', sortable: true },
				{ text: 'Nama Lengkap', value: 'fullname', align: 'left', sortable: true },
				{ text: 'Izin akses', value: 'access', align: 'left', sortable: true },
			],
			dataList: []
		},

		emailReplyModal = {
			isShow: false,
			allowedTicket: false,
			tempAllowedTicket: false,
		},

		currentData = {
			user_id: '',
			username: '',
			fullname: '',
			email: '',
			groups: '',
			rank: '',
            position: '',
            profile_picture: '',
            isBsre: '',
			nik: '',
			division: ''
		}
        return {
			division,
            avatar,
            logo,
            modalUser,
            formUser,
            modalPassword,
            formPassword,
            modalPhotoUser,
            formPhotoUser,
            spriModal,
            emailReplyModal,
			isToggleShowOldPassword: false,
			isToggleShowNewPassword: false,
			isToggleShowConfirmPassword: false,
			currentData
        }
	},
	computed: {
        ...mapState('auth', {
			user_id: 'user_id',
			username: 'username',
			fullname: 'fullname',
			email: 'email',
			groups: 'groups',
			rank: 'rank',
            position: 'position',
            profile_picture: 'profile_picture_thumb_base64',
            isBsre: 'isBsre',
			nik: 'nik',
			satker: 'satker'
        }),
        ...mapState('user', {
			uploadProgressBtn: 'uploadProgressBtn'
		}),
		
		isHaveProfilePicture() {
			if(this.profile_picture == "") {
				return false
			} return true
		},
		oldPasswordIcon(){
			return this.showIconEye(this.isToggleShowOldPassword)
		},
		oldPasswordType(){
			return this.showPassword(this.isToggleShowOldPassword)
		},
		newPasswordIcon(){
			return this.showIconEye(this.isToggleShowNewPassword)
		},
		newPasswordType(){
			return this.showPassword(this.isToggleShowNewPassword)
		},
		confirmPasswordIcon(){
			return this.showIconEye(this.isToggleShowConfirmPassword)
		},
		confirmPasswordType(){
			return this.showPassword(this.isToggleShowConfirmPassword)
		},
    },
	watch: {
		uploadProgressBtn: {
            handler: function (newValue, oldValue) {
				if (newValue) {
					if (newValue >= 0 && newValue <= 100) this.formPhotoUser.submitBtn.text = 'Memproses... ('+newValue+'%)'
					if (newValue == 100) {
						setTimeout( e => { 
							this.formPhotoUser.submitBtn.text = 'Unggah selesai, mohon menunggu!'
						}, 1000)
					}
				}
            },
            deep: true,
            imediate: true
		}
	},
	mounted () {
		this.setCurrentData()
	},
	methods: {
		setCurrentData() {		
			this.currentData.user_id= this.user_id
			this.currentData.username= this.username
			this.currentData.fullname= this.fullname
			this.currentData.email= this.email
			this.currentData.groups= this.groups
			this.currentData.rank= this.rank
            this.currentData.position= this.position
            this.currentData.profile_picture= this.profile_picture
            this.currentData.isBsre= this.isBsre
			this.currentData.nik= this.nik
			this.currentData.division = this.satker
		},
		showIconEye(condition) {
			if(condition)
				return 'eye-slash'
			return 'eye'
		},
		showPassword(condition) {
			if(condition)
				return 'text'
			return 'password'
		},
		fetchData () {
			this.document.loader = true
			return new Promise((resolve, reject) => {
				this.$store.dispatch({
					type: 'auth/getUserProfile',
				}).then( res => {
					let data = res.result[0]
					this.currentData.division = data.satker
				}).catch ( err => {
					console.log('Failed to fetch user profile data')
					console.log(err)
				})
			})
		},
        showFormModal () {
            this.formUser.input = {
				userId: this.user_id,
				username: this.username,
				fullname: this.fullname,
				email: this.email,
				nik: this.nik
			}
            this.validateForm('userId')
            this.validateForm('username')
			this.modalUser.isShow = true
		},
		closeFormModal () {
			this.formUser.input = Object.assign({}, this.formUser.defaultValue)
			this.formUser.validate = {
				userId: null,
				username: null,
				groups: null,
				password: null,
				confirmPassword: null
            }
            this.formUser.submitBtn.text = 'Simpan'
			this.formUser.submitBtn.disabled = true
			this.modalUser.isShow = false
		},
		validateForm (field) {
			if(field == 'userId'){
				if(this.formUser.input.userId != 0){
					this.formUser.validate.userId = true
				}else{
					this.formUser.validate.userId = false
					return false
				}
			}

			if(field == 'username'){
				if(this.formUser.input.username != ''){
					this.formUser.validate.username = true
				}else{
					this.formUser.validate.username = false
					return false
				}
			}

			if(
				this.formUser.validate.userId &&
				this.formUser.validate.username
			){
				this.formUser.submitBtn.disabled = false
			}
		},
		getUserProfileData() {
			this.$store.dispatch('auth/getUserProfile').then((res) => {
				if(res!= undefined) {
					this.currentData.user_id=  res.user.user_id
					this.currentData.username=  res.user.username
					this.currentData.fullname=  res.user.fullname
					this.currentData.email=  res.user.email
					this.currentData.groups=  res.user.groups
					this.currentData.rank=  res.user.rank
					this.currentData.position=  res.user.position
					this.currentData.profile_picture= (res.user.profile_picture_thumb_base64)? "data:image/jpeg;base64,"+res.user.profile_picture_thumb_base64:''
					this.currentData.isBsre=  res.user.is_bsre
					this.currentData.nik=  res.user.nik
					this.currentData.division= res.user.satker
				}
			})
		},
		updateItem () {
            this.formUser.submitBtn = {
                loader: true,
                text: 'Memproses...'
            }
			this.$store.dispatch({
				type: 'user/editUser',
				formInput: this.formUser.input
			}).then( res => {
				this.getUserProfileData()
                this.$toasted.success('Sukses mengubah data pengguna').goAway(3000)
                this.formUser.submitBtn = {
                    loader: false,
                    text: 'Simpan'
                }
                setTimeout( e => {
                    this.closeFormModal()
                }, 500)
			}).catch( err => {
				console.log(err)
                this.formUser.submitBtn = {
                    loader: false,
                    text: 'Simpan'
                }
				this.$toasted.error('Gagal mengubah data pengguna, silahkan coba kembali!').goAway(3000)
			})
		},
		showPasswordFormModal () {
			this.formPassword.input = {
				userId: this.user_id,
				password: '',
				newPassword: '',
				confirmPassword: ''
			}
			this.validatePassword('userId')
			this.formPassword.validate.password = false
			this.formPassword.validate.newPassword = false
			this.formPassword.validate.confirmPassword = false
			this.modalPassword.isShow = true
		},
		closePasswordFormModal () {
			this.formPassword.input = Object.assign({}, this.formPassword.defaultValue)
			this.formPassword.validate = {
				userId: null,
				password: null,
				newPassword: null,
				confirmPassword: null
            }
            this.formPassword.submitBtn.text = 'Simpan'
			this.formPassword.submitBtn.disabled = true
			this.modalPassword.isShow = false
		},
		validatePassword (field) {
			if(field == 'userId'){
				if(this.formPassword.input.userId != 0){
					this.formPassword.validate.userId = true
				}else{
					this.formPassword.validate.userId = false
					return false
				}
			}

			if(field == 'password'){
				if(this.formPassword.input.password != '' && this.modalPassword.isShow == true){
					var password = this.formPassword.input.password
					this.$store.dispatch(
						'auth/loginV1', 
						{
							username:this.username,
							password:this.formPassword.input.password
						}
					).then( (res) => {
						if(res == false) {
							this.formPassword.validate.password = false
						}
						else if(res.data.is_blocked) {
							this.$toasted.success('Akun anda terkunci selama 15 menit, silahkan coba kembali setelah 15 menit').goAway(3000)
						}
                        else if(res.data.token != null){
                            this.formPassword.validate.password = true
                        }else {
							this.formPassword.validate.password = false
                        }
                    })
				}else{
					this.formPassword.validate.password = false
					return false
				}
			}

			if(field == 'newPassword'){
				if(this.formPassword.input.newPassword != ''){
					this.formPassword.validate.newPassword = true
				}else{
					this.formPassword.validate.newPassword = false
					return false
				}
			}

			if(field == 'confirmPassword'){
				if(
					this.formPassword.input.confirmPassword != '' &&
					this.formPassword.input.confirmPassword == this.formPassword.input.newPassword
				){
					this.formPassword.validate.confirmPassword = true
				}else{
					this.formPassword.validate.confirmPassword = false
					return false
				}
			}

			if(
				this.formPassword.validate.password &&
				this.formPassword.validate.newPassword &&
				this.formPassword.validate.confirmPassword
			){
				this.formPassword.submitBtn.disabled = false
			}
		},
		updatePassword () {
            this.formPassword.submitBtn = {
                loader: true,
                text: 'Memproses...'
            }
			this.$store.dispatch({
				type: 'user/editPasswordUser',
				formInput: this.formPassword.input
			}).then( res => {
				localStorage.removeItem('token')
				localStorage.setItem('token', res.result.new_token)
				this.getUserProfileData()
                this.$toasted.success('Sukses mengubah data pengguna').goAway(3000)
                this.formPassword.submitBtn = {
                    loader: false,
                    text: 'Simpan'
                }
                setTimeout( e => {
                    this.closePasswordFormModal()
                }, 500)
			}).catch( err => {
				console.log(err)
                this.formPassword.submitBtn = {
                    loader: false,
                    text: 'Simpan'
                }
				this.$toasted.error('Gagal mengubah data pengguna, silahkan coba kembali!').goAway(3000)
			})
		},
        showPhotoFormModal () {
            this.formPhotoUser.input = {
				file: []
			}
			this.modalPhotoUser.isShow = true
		},
		closePhotoFormModal () {
            this.formPhotoUser.input = Object.assign({}, this.formPhotoUser.defaultValue)
			this.formPhotoUser.placeholderFile = 'Belum memilih foto/gambar'
			this.formPhotoUser.validate = {
				file: null
			}
			this.modalPhotoUser.isShow = false
        },
        validatePhoto (event) {
            if(event.length > 0){
				var size = event[0].size
				if(size > 10485760) { //10Mb
					this.$toasted.error('Ukuran file foto tidak boleh lebih dari 10MB').goAway(3000)
					this.formPhotoUser.validate.file = false
				} else {
					var Extension = event[0].name.substring(event[0].name.lastIndexOf('.') + 1).toLowerCase()

					if (	Extension == "gif" || 
							Extension == "png" || 
							Extension == "bmp" || 
							Extension == "jpg" ||
							Extension == "jpeg") {
						let fileCollection = []
						event.forEach((item) => {
							fileCollection.push(item.name)
						})
						this.formPhotoUser.placeholderFile = fileCollection.join()
						this.formPhotoUser.input.file = event

						this.formPhotoUser.validate.file = true
					} else {
						this.$toasted.error('Hanya boleh memilih file gambar').goAway(3000)
						this.formPhotoUser.validate.file = false
					}
				}
            }else{
                this.formPhotoUser.placeholderFile = 'Belum memilih foto'
                this.formPhotoUser.validate.file = false
            }
            
            if(this.formPhotoUser.validate.file) this.formPhotoUser.submitBtn.disabled = false
		},
        updatePhotoItem () {
			this.formPhotoUser.submitBtn = {
				loader: true,
				text: 'Memproses...',
				disabled: true
			}
            this.formPhotoUser.submitBtn.loader = true
			this.$store.dispatch({
				type: 'user/editPhotoUser',
				formInput: this.formPhotoUser.input
			}).then( res => {
				console.log("Res: ", res)
				
                this.getUserProfileData()
                this.$toasted.success('Sukses mengubah data pengguna').goAway(3000)
                setTimeout( e => {
                    this.closePhotoFormModal()
                }, 500)
			}).catch( err => {
                console.log(err)
				this.$toasted.error('Gagal mengubah data pengguna, silahkan coba kembali!').goAway(3000)
			}).finally( () => {
				this.formPhotoUser.submitBtn = {
                    loader: false,
                    text: 'Simpan',
					disabled: true
                }
			})
        },

        // spri
        showSpriModal() {
            this.spriModal.isShow = true

            this.$store.dispatch({
					type: 'user/getUserSpri',
					user_id: this.user_id
				}).then( res => {
					let data = res.result
					this.spriModal.dataList = data

				}).catch ( err => {
					console.log('Failed to fetch user profile data')
					console.log(err)
				})
        },
        changeAccess(item) {
            let changeStat = true
            if (item.is_link_user_active){
                changeStat = false
            }

            this.$store.dispatch(
                {
                    type: 'user/putSpriAccess',
                    formInput: {
                        linkUser: this.user_id,
                        is_link_user_active: changeStat,
                    },
                    userId: item.user_id
                }
            ).then(res=> {
                this.showSpriModal()
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
            })

        },

        showEmailModal(){
            this.emailReplyModal.isShow = true
            this.emailReplyModal.allowedTicket = this.emailReplyModal.tempAllowedTicket
        },

        updateEmail(){
            this.emailReplyModal.isShow = false
            this.emailReplyModal.tempAllowedTicket = this.emailReplyModal.allowedTicket
        },

        inputCheckAllowed(value) {
            this.emailReplyModal.allowedTicket = value
        },

		deletePhotoItem() {
			this.$confirm({
                auth: false,
                message: 'Apakah yakin ingin menghapus foto profil?',
                button: {
                    no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch({
							type: 'user/deletePhotoUser',
							userId: this.user_id
						}).then( res => {
							this.getUserProfileData()
							this.$toasted.success('Sukses mengubah data pengguna').goAway(3000)
						}).catch( err => {
							console.log(err)
							this.$toasted.danger('Gagal mengubah data pengguna, silahkan coba kembali!').goAway(3000)
						})
					}
				}
			})
		}
    }
}
</script>

<style scoped>
.container{
    padding: 20px;
}
.mt-10 {
	margin-top: 10px;
}
</style>